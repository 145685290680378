import { useEffect } from "react";
import { getSessionId } from "./Apis";

export default function SessionWrapper({ children }) {
  useEffect(() => {
    console.log("SessionWrapper")
    if (!localStorage.getItem("sessionId")) {
      getSessionId().then((sessionId) => {
        console.log("sessionId",sessionId)
        localStorage.setItem("sessionId", sessionId);
      });
    }
  }, []);
  return <>{children}</>;
}
import openSocket from "socket.io-client";
import { adminPath, serverPath } from "../config/keys";

export const socket = openSocket(serverPath, {
  transports: ["websocket"],
  rejectUnauthorized: false,
  reconnection: false,
});

socket.on("connect", () => {
  console.log("connection created with landing backend");
});

socket.on("connect_error", (error) => {
  console.error("Connection error:", error);
});

socket.on("disconnect", (reason) => {
  console.log("Disconnected:", reason);
});

export const adminSocket = openSocket(adminPath, {
  transports: ["websocket"],
  rejectUnauthorized: false,
  reconnection: false,
});

socket.on("connect", () => {
  socket.on("uploadimage", () => {
    console.log("=================", "receidved");
  });
  console.log("connection created with landing backend");
});

socket.on("connect_error", (error) => {
  console.error("Connection error:", error);
});

socket.on("disconnect", (reason) => {
  console.log("Disconnected:", reason);
});

/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import './style.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import logo from "../../assets/images/header/logo.svg";
import VerseLogo from '../../assets/images/header/image_2024_11_26T09_29_11_967Z-removebg-preview.png'
import visa from '../../assets/images/sidebar/visa.svg'
import master from '../../assets/images/sidebar/mastercard.svg'
import close from '../../assets/images/icons/closesidebar.svg'
import { useMyContext } from '../context'
import { jwtDecode } from 'jwt-decode'
//import { set } from 'react-hook-form'

//import AudioPlayer from "../AudioPlayer";
// import ResponsiveLanguage from "../language/responsiveLanguage";

function Sidebar() {
  const {
    handleShowTransection,
    menu,
    handleMenuClickClose,
    isGamePlaying,
    // gameShow,
    setGameShow,
    windowWidth,
    gameSubCategory,
    setShowLogin,
    loading,
    activeCategory,
    setActiveCategory,
    setShowLobbyGames
  } = useMyContext()
  const navigate = useNavigate()
  const location = useLocation()
  const currPath = location.pathname
  const [isOpen, setIsOpen] = useState(false)
  const token = localStorage.getItem('token')
  // const [isDropOpen, setIsDropOpen] = useState(false);
  const handleClick2 = () => {
    setIsOpen(!isOpen)
  }

  // const handleDropClick = () => {
  //   setIsDropOpen(!isDropOpen);
  // };
  useEffect(() => {
    const accessToken = localStorage.getItem('token')
    if (accessToken) {
      const decodedToken = jwtDecode(accessToken)
      const expirationTimeInSeconds = decodedToken.exp || 0
      const currentTimeInSeconds = Math.floor(Date.now() / 1000)
      // const timeLeft = expirationTimeInSeconds - currentTimeInSeconds;
      // const hours = Math.floor(timeLeft / 3600);
      // const minutes = Math.floor((timeLeft % 3600) / 60);
      // const seconds = timeLeft % 60;

      if (expirationTimeInSeconds <= currentTimeInSeconds) {
        localStorage.clear()
        window.location.reload()
      }
    }
    // eslint-disable-next-line
  }, [loading, token, currPath])

  // useEffect(() => {
  //   const desiredURL = "/casino/casino";
  //   if (window.location.pathname === desiredURL) {
  //     setIsOpen(true);
  //   } else {
  //     setIsOpen(false);
  //   }
  // }, []);

  useEffect(() => {
    if (!isOpen && activeCategory === 'lobby') {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory])

  useEffect(() => {
    const desiredURL = '/casino/livecasino'
    if (window.location.pathname === desiredURL) {
      // setIsDropOpen(true);
    } else {
      // setIsDropOpen(false);
    }
  }, [])

  const handleClick = () => {
    setGameShow(false)
    if (isGamePlaying) {
      setActiveCategory('lobby');
      setShowLobbyGames(true)
      // navigate('/view-all/lobby/lobby')
    }
  }
  const handleNavigation = (to) => {
    const token = localStorage.getItem('token') || ''
    if (token) {
      navigate(to)
    } else {
      setShowLogin(true)
    }
  }

  return (
    <nav id="sidebar" className={`box ${menu ? 'visible' : ''}`}>
      <div className="left" onClick={handleClick}>
        <Link to="/" className="logo">
          {/* <img src={logo} alt="" /> */}
          <img src={VerseLogo} alt="" />
        </Link>
      </div>
      <div className="close" onClick={handleMenuClickClose}>
        <img src={close} alt="close" />
      </div>
      <div className="sections-wrapper">
        <section className="menu">
          <ul>
            <li className="casino">
              <ul className="dropdown-menus" id="casino-livecasino">
                <li className="slots">
                  <div
                    className={`icon-wrapper arrow-item icon-shadow ${
                      isOpen ? 'active' : ''
                    }`}
                    onClick={handleMenuClickClose}
                  >
                    <span
                      // to={handleNavigation("/all-games")}
                      className={`options-list`}
                      text_key="MENU__SLOT"
                      onClick={() => {
                         setActiveCategory('lobby')
                         setShowLobbyGames(true)
                        // handleNavigation('/view-all/lobby/lobby')
                      }}
                    >
                      ALL GAMES
                    </span>
                    <div
                      className={`all-games-down  ${
                        isOpen ? 'menu-open' : ''
                      }  `}
                      onClick={handleClick2}
                    >
                      <DownArrowSign />
                    </div>
                    {/* <div className="arrow" onClick={handleClick}></div> */}
                  </div>
                  <ul
                    className={`dropdown-menus ${
                      windowWidth >= 991 && isOpen ? 'open-box' : 'close-box'
                    }`}
                    id="casino"
                  >
                    <li>
                      <span
                        className={`options-inner ${
                          activeCategory ===
                            'favorite' && 'active-nav-item'
                        }`}
                        onClick={() =>{
                          setActiveCategory('favorite')
                          setShowLobbyGames(false)
                          // handleNavigation(`/view-all/${'favorite'}/favorite`)
                         } }
                      >
                        Favorite
                      </span>
                    </li>
                    {gameSubCategory &&
                      gameSubCategory
                        ?.sort((a, b) => {
                          // If a category doesn't have a rank, place it at the top
                          if (!a.category_rank) return -1
                          if (!b.category_rank) return 1
                          // Otherwise, sort by rank
                          return a.category_rank - b.category_rank
                        })
                        ?.map((el) => (
                          <li
                            category={el?.name}
                            key={el?.masterGameSubCategoryId}
                          >
                            <span
                              className={`options-inner ${
                                activeCategory ==
                                  el?.masterGameSubCategoryId &&
                                'active-nav-item'
                              }`}
                              onClick={() =>{
                                setActiveCategory(el?.masterGameSubCategoryId)
                                setShowLobbyGames(false)
                              }
                                
                                // handleNavigation(
                                //   `/view-all/${
                                //     el?.masterGameSubCategoryId
                                //   }/${el?.name?.replace(/\s+/g, '-')}`,
                                // )
                              }
                            >
                              {el?.name}
                            </span>
                          </li>
                        ))}
                  </ul>
                </li>
                <li className="live-casino">
                  {/* <div
                    className={`icon-wrapper arrow-item icon-shadow ${
                      isDropOpen ? "active" : ""
                    }`}
                    onClick={handleMenuClickClose}
                  >
                    <Link to="/" text_key="MENU__LIVE_CASINO">
                      LIVE CASINO
                    </Link>
                    <div className="arrow" onClick={handleDropClick}></div>
                  </div> */}
                  {/* {windowWidth >= 991 && isDropOpen && ( */}
                  {/* <ul
                    className={`dropdown-menus ${
                      windowWidth >= 991 && isDropOpen
                        ? "open-box"
                        : "close-box"
                    }`}
                    id="livecasino"
                  >
                    <li category="FAV">
                      <Link to="/" text_key="CASINO__FAVORITE">
                        My Favorites
                      </Link>
                    </li>

                    <li category="14">
                      <Link to="/" text_key="CASINO__LIVE_BACCARAT">
                        Live Baccarat
                      </Link>
                    </li>

                    <li category="13">
                      <Link to="/" text_key="CASINO__LIVE_BLACKJACK">
                        Live Blackjack
                      </Link>
                    </li>

                    <li category="9">
                      <Link to="/" text_key="CASINO__NEW">
                        New Games
                      </Link>
                    </li>

                    <li category="12">
                      <Link to="/" text_key="CASINO__LIVE_ROULETTE">
                        Live Roulette
                      </Link>
                    </li>

                    <li category="15">
                      <Link to="/" text_key="CASINO__CASINO_HOLDEM">
                        Casino Holdem
                      </Link>
                    </li>

                    <li category="16">
                      <Link to="/" text_key="CASINO__LIVE_POKER">
                        Live Poker
                      </Link>
                    </li>

                    <li category="11">
                      <Link to="/" text_key="CASINO__OTHER">
                        Other
                      </Link>
                    </li>

                    <li category="35">
                      <Link to="/" text_key="CASINO__GAME SHOWS">
                        Game Shows
                      </Link>
                    </li>

                    <li category="27">
                      <Link to="/" text_key="CASINO__POPULAR_CASINO">
                        Popular Casino
                      </Link>
                    </li>

                    <li category="2">
                      <Link to="/" text_key="CASINO__VIDEO_SLOTS">
                        Video Slots
                      </Link>
                    </li>

                    <li category="17">
                      <Link to="/" text_key="CASINO__LIVE_DICE">
                        Live Dice
                      </Link>
                    </li>

                    <li category="18">
                      <Link to="/" text_key="CASINO__SIC_BO">
                        Sic Bo
                      </Link>
                    </li>

                    <li category="1">
                      <Link to="/" text_key="CASINO__TABLE_GAMES">
                        Table Games
                      </Link>
                    </li>

                    <li category="28">
                      <Link to="/" text_key="CASINO__REGULAR_TABLE">
                        Regular Table
                      </Link>
                    </li>

                    <li category="10">
                      <Link to="/" text_key="CASINO__POPULAR_SLOT">
                        Popular Slots
                      </Link>
                    </li>
                  </ul> */}
                  {/* )} */}
                </li>
              </ul>
            </li>
            {/* <li
              className="icon promo icon-shadow"
              onClick={handleMenuClickClose}
            >
              <Link to="/static/promos" text_key="MENU__PROMO">
                PROMOTIONS
              </Link>
            </li> */}

            <li className="buy-crypto">
              <span
                className="btn"
                onClick={() => {
                  handleShowTransection('deposit')
                }}
              >
                <span text_key="MENU__BUY_CRYPTO">Buy Crypto</span>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_652_49)">
                    <path
                      opacity="0.3"
                      d="M6.5 11.2185C5.94772 11.2185 5.5 11.6662 5.5 12.2185C5.5 12.7708 5.94772 13.2185 6.5 13.2185H18.5C19.0523 13.2185 19.5 12.7708 19.5 12.2185C19.5 11.6662 19.0523 11.2185 18.5 11.2185H6.5Z"
                      fill="white"
                    ></path>
                    <path
                      d="M11.7929 17.5114C11.4024 17.9019 11.4024 18.5351 11.7929 18.9256C12.1834 19.3161 12.8166 19.3161 13.2071 18.9256L19.2071 12.9256C19.5857 12.547 19.5989 11.9374 19.2372 11.5428L13.7372 5.54278C13.364 5.13566 12.7314 5.10816 12.3243 5.48135C11.9172 5.85454 11.8897 6.48711 12.2628 6.89423L17.1159 12.1884L11.7929 17.5114Z"
                      fill="white"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_652_49">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.5 0.218506)"
                      ></rect>
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <div className="imgs">
                <img src={visa} alt="visa" />{' '}
                <img src={master} alt="mastercard" />
              </div>
            </li>
            <li
              className="static icon support icon-shadow all-games-mobile"
              // onClick="LC_API.open_chat_window()"
            >
              <a
                href="/view-all/lobby/lobby"
                onClick={(e) => {
                  e.preventDefault() // Prevent default anchor behavior
                  handleNavigation('/view-all/lobby/lobby')
                  handleMenuClickClose()
                }}
                text_key="MENU__SLOT"
                className="fontStyle"
              >
                All Games
              </a>
            </li>
            {/* <li
              className="static icon support icon-shadow"
              // onClick="LC_API.open_chat_window()"
            >
              <Link to="/" text_key="MENU__LIVE_SUPPORT " className="fontStyle">
                Live chat 24/7
              </Link>
            </li> */}
            {/* <li className="static icon affiliate icon-shadow">
              <Link
                className="link fontStyle"
                // to="/en/static/affiliate"
                to="/"
                text_key="MENU__AFFILIATE"
              >
                Affiliate
              </Link>
            </li> */}
          </ul>
          {/* {windowWidth <= 991 && <ResponsiveLanguage />} */}
        </section>
      </div>
      {/* <AudioPlayer /> */}
    </nav>
  )
}

export default Sidebar

export const DownArrowSign = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g
        id="Group_1369"
        data-name="Group 1369"
        transform="translate(16 16) rotate(180)"
      >
        <circle
          id="Ellipse_68"
          data-name="Ellipse 68"
          cx="8"
          cy="8"
          r="8"
          transform="translate(0 0)"
          fill="none"
        />
        <path
          id="Path_3399"
          data-name="Path 3399"
          d="M.817,7.147l-.8-5.727a1.176,1.176,0,0,1,1.4-1.4l5.727.8"
          transform="translate(4.086 7.831) rotate(-45)"
          fill="none"
          stroke="#97a1c2"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.6"
        />
      </g>
    </svg>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import Slider from "react-slick";
import play from "../../assets/images/casinogame/gamecard/play.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
// import { startselectedGame } from "../../utility/Apis";
import { Spinner } from "react-bootstrap";
// import { useMyContext } from "../context";
//import GamePopup from "../casino/gamePopup";
import { useMyContext } from "../context";
import { getLandingGames, makeUserFavoriteGame } from "../../utility/Apis";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Grid, FreeMode } from "swiper/modules";

function Lobby({ setShowLobbyGames }) {
  const {
    loading,
    setLoading,
    setIsGamePlaying,
    handleLoginShow,
    favorites,
    setFavorites,
    setActiveCategory
  } = useMyContext();
  const [landingGames, setLandingGames] = useState([]);

  const handleChangeFav = async (gameId) => {
    try {
      const res = await makeUserFavoriteGame(gameId);
      const {
        data: { message },
      } = res || {};

      if (res?.status === 200) {
        setFavorites((prevFavorites) => {
          // Check if the game is already in favorites
          if (prevFavorites.includes(gameId)) {
            // Remove the game immutably if it exists in favorites
            return prevFavorites.filter((favId) => favId !== gameId);
          } else {
            // Add the game immutably if it doesn't exist in favorites
            return [...prevFavorites, gameId];
          }
        });

        return toast.success(message, {
          toastId: "send-code",
          autoClose: 2000,
        });
      }
    } catch (err) {
      return toast.error("Something went wrong!", {
        toastId: "send-code",
        autoClose: 2000,
      });
    }
  };

  // const { gameShow, setGameShow } = useMyContext();
  // const [
  //   // isPrevButtonDisabled,
  //   setPrevButtonDisabled,
  // ] = useState(true);
  // const [
  //   // isNextButtonDisabled,
  //   setNextButtonDisabled,
  // ] = useState(false);
  // const [
  //   ,
  //   // currentSlideIndex
  //   setCurrentSlideIndex,
  // ] = useState(0);
  // const handleGameShow = () => {
  //   setGameShow(true);
  // };

  // const handleGameClose = () => {
  //   navigate("/view-all/false/lobby/lobby");;
  //   setGameShow(false);
  //   setIsGamePlaying(false);
  // };

  // const headerGamePopup = gameShow && "gamePopup";

  // landing games api is calling here
  const fetchLandingGames = async () => {
    try {
      setLoading(true);
      sessionStorage.removeItem("pageSize");
      const res = await getLandingGames();
      if (res?.msg === "success") {
        setLandingGames(res?.data);
      } else {
        toast.error(res?.msg, { toastId: "game-error" });
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLandingGames();
    // eslint-disable-next-line
  }, []);

  // const settings = {
  //   dots: false,
  //   arrows: false,
  //   infinite: false,
  //   speed: 200,
  //   slidesToScroll: 1,
  //   slidesToShow: 5,
  //   swipeToSlide: true,
  //   touchMove: true,
  //   adaptiveHeight: true, // Adjust height based on current slide
  //   draggable: true,
  //   waitForAnimate: false,
  //   rows: 2,
  //   responsive: [
  //     {
  //       breakpoint: 1600,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 1,
  //       },
  //     },

  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 2,
  //         swipe: true,
  //         swipeToSlide: true,
  //         touchMove: true,
  //       },
  //     },
  //   ],
  // };

  const settings = {
    spaceBetween: 10,
    slidesPerView: 5,
    freeMode: true,
    // grabCursor: true,
    speed: 100,
    mousewheel: {
      releaseOnEdges: true,
    },

    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
        grid: {
          rows: 2,
          fill: "row",
        },
      },
      425: {
        slidesPerView: 2,
        spaceBetween: 10,
        grid: {
          rows: 2,
          fill: "row",
        },
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 10,
        grid: {
          rows: 2,
          fill: "row",
        },
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 5,
        spaceBetween: 20,
      },
    },
  };

  const navigate = useNavigate();

  // const [game, setGame] = useState({});
  const sliderRefs = {
    "New Games": React.createRef(),
    Slots: React.createRef(),
    "Table Game": React.createRef(),
    "Video Poker": React.createRef(),
    "Table & Cards": React.createRef(),
    "Instant Win": React.createRef(),
    Poker: React.createRef(),
    // "Live Dealer": React.createRef(),
  };
  // const updateButtonState = (category) => {
  //   if (
  //     sliderRefs[category] &&
  //     sliderRefs[category].current &&
  //     sliderRefs[category].current.innerSlider
  //   ) {
  // const currentSlide =
  //   sliderRefs[category].current.innerSlider.state.currentSlide;
  // setCurrentSlideIndex(currentSlide);
  // setPrevButtonDisabled(currentSlide === 0);
  // setNextButtonDisabled(
  //   currentSlide === landingGames[category].length - settings.slidesToShow
  // );
  //   }
  // };

  // const [nextArray, setNextArray] = useState([]);
  // const [preArray, setPreArray] = useState([]);

  // const gameArray = Object.keys(landingGames);

  // useEffect(() => {
  //   if (gameArray?.length) {
  //     setPreArray([...gameArray?.map((el, index) => index)]);
  //   }
  // }, [gameArray?.length]);

  // const goToSlide = (category, index, length, direction) => {
  //   const slideCategoryLength =
  //     sliderRefs[category].current.innerSlider.state.currentSlide;
  //   const difference = length - slideCategoryLength;

  //   if (direction === "previous") {
  //     if (difference >= 5) {
  //       setNextArray(updateArray(nextArray, index, "remove"));
  //     }

  //     if (slideCategoryLength === 1) {
  //       setPreArray(updateArray(preArray, index, "add"));
  //     }

  //     sliderRefs[category].current.slickPrev();
  //   } else if (direction === "next") {
  //     if (difference === 6) {
  //       setNextArray(updateArray(nextArray, index, "add"));
  //     }

  //     if (slideCategoryLength === 0) {
  //       setPreArray(updateArray(preArray, index, "remove"));
  //     }

  //     if (difference !== 5) {
  //       sliderRefs[category].current.slickNext();
  //     }
  //   }

  //   updateButtonState(category);
  // };

  // const updateArray = (array, index, action) => {
  //   const copy = [...array];
  //   const fIndex = copy.indexOf(index);
  //   if (action === "add" && fIndex === -1) {
  //     copy.push(index);
  //   } else if (action === "remove" && fIndex >= 0) {
  //     copy.splice(fIndex, 1);
  //   }
  //   return copy;
  // };

  // const handleAfterChange = (category) => {
  //   updateButtonState(category);
  // };
  const handleClick = (name, id, filterBy) => {
    if (name === "New Games") {
      // navigate("/view-all/false/lobby/lobby");
      // setShowLobbyGames(true);
      setActiveCategory("new_games");
      //navigate(`/view-all/${id}/${name?.replace(/\s+/g, "-")}`);

      setShowLobbyGames(false);
    } else if (filterBy === "category") {
      setShowLobbyGames(false)
      setActiveCategory(id);
       //navigate(`/view-all/${id}/${name?.replace(/\s+/g, "-")}`);
      //setShowLobbyGames(false);
    }
  };

  const handleClickPlay = (game) => {
    if (!localStorage.getItem("token")) {
      handleLoginShow();
    } else {
      navigate(`/game/${game?.masterCasinoGameId}`);
      // setGame(game);
      setIsGamePlaying(true);
      const scrollPos = window.pageYOffset;
      sessionStorage.setItem("scrollPosition", `${scrollPos}`);
    }
  };

  useEffect(() => {
    if (!loading) {
      const savedScrollPosition = sessionStorage.getItem("scrollPosition");
      if (savedScrollPosition) {
        window.scrollTo(0, parseInt(savedScrollPosition));
        // sessionStorage.removeItem("scrollPosition");
      }
    }
  }, [Object.keys(landingGames)?.length > 0, loading]);
  return (
    <>
      <section className="mini-games game-head-wrapper">
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 99,
              textAlign: "center",
              height: "80vh",
              width: "100%",
            }}
          >
            <Spinner
              as="span"
              animation="border"
              size="md"
              role="status"
              aria-hidden="true"
              variant="light"
              style={{ height: "50px", width: "50px" }}
            />
          </div>
        ) : (
          Object.keys(landingGames)?.map((category, index) => (
            <div className="mini-games-wrapper" key={index}>
              <div className="head-wrapper">
                <div className="d-flex justify-content-between align-items-center w-100">
                  <h1 className="title">{category}</h1>
                  <div
                    className="view-more"
                    onClick={() =>
                      handleClick(
                        category,
                        landingGames[category]?.games[0]
                          ?.masterGameSubCategoryId,
                        "category"
                      )
                    }
                  >
                    <Link>See all</Link>
                    <span className="count ms-1">
                      ({landingGames[category]?.totalGames})
                    </span>
                  </div>
                </div>
                {/* <div className="right">
                  <div
                    className="view-more"
                    onClick={() =>
                      handleClick(
                        category,
                        landingGames[category][0]?.masterGameSubCategoryId,
                        "category"
                      )
                    }
                  >
                    <Link>See all</Link>
                    <span className="count">
                      {landingGames[category]?.length}
                    </span>
                  </div>
                  <div className="slider-btn-wrapper">
                    <div
                      className={`swiper-prev ${
                        preArray.includes(index)
                          ? "swiper-button-disabled"
                          : " "
                      }`}
                      onClick={() =>
                        goToSlide(
                          category,
                          index,
                          landingGames[category]?.length,
                          "previous"
                        )
                      }
                    ></div>
                    <div
                      className={`swiper-next ${
                        nextArray.includes(index)
                          ? "swiper-button-disabled"
                          : ""
                      }`}
                      onClick={() =>
                        goToSlide(
                          category,
                          index,
                          landingGames[category]?.length,
                          "next"
                        )
                      }
                    ></div>
                  </div>
                </div> */}
              </div>
              <div className="game-swiper-container">
                <Swiper
                  ref={sliderRefs[category]}
                  // direction="horizontal"
                  {...settings}
                  // handleAfterChange
                  modules={[Grid, FreeMode]}
                >
                  {landingGames[category]?.games?.map((game) => (
                    <SwiperSlide key={game?.masterCasinoGameId}>
                      <div className="game-swiper-slider">
                        <div className="game-swiper-slider-img">
                          <div
                            className="game-thumb-container"
                            key={game?.masterCasinoGameId}
                          >
                            <img src={game?.thumbnailUrl} alt={game?.name} />
                          </div>
                          <div
                            className="slot-hover"
                            // onClick={() => handleClickPlay(game)}
                          >
                            <span>
                              <img
                                className="play"
                                src={play}
                                alt="play icon"
                                onClick={() => handleClickPlay(game)}
                              />
                            </span>
                            <div className="star-wrapper">
                              <span
                                className="material-icons"
                                onClick={() =>
                                  handleChangeFav(game?.masterCasinoGameId)
                                }
                              >
                                {favorites?.length > 0 &&
                                favorites?.includes(game?.masterCasinoGameId)
                                  ? "favorite"
                                  : "favorite_border"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="pt-2 text-center game-title">
                        {game?.name?.length > 30
                          ? game?.name?.substring(0, 30) + "..."
                          : game?.name}
                      </p>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          ))
        )}
      </section>
    </>
  );
}

export default Lobby;

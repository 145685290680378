/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from 'react'
import Layout from '../layout/layout'
// import GameSlider from "./gameSlider";
import GameSearch from './gameSearch'
import './style.css'
import GameCard from './gameCard'
//import GamePopup from "./gamePopup";
import { getAllProviders, getAllgamesByCategory } from '../../utility/Apis'
import { useMyContext } from '../context'
import { toast } from 'react-toastify'
// import titleLogo from "../../assets/images/casinogame/banner/icon.svg";
// import arrowdown from "../../assets/images/icons/arrow-down.svg";
import rightArrow from '../../assets/images/casinogame/banner/rightArrow.svg'
import leftArrow from '../../assets/images/casinogame/banner/leftArrow.svg'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// import Slider from "react-slick";
import Lobby from '../lobby/lobby'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Keyboard, Scrollbar, Navigation, FreeMode } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/grid'

function Casino() {
  const {
    loading: loadingShowMore,
    setLoading: setShowMoreLoading,
    userDetails,
    handleLoginShow,
    windowWidth,
    gameSubCategory,
    setFavorites,
    activeCategory,
    setActiveCategory,
    showLobbyGames,
    setShowLobbyGames,
  } = useMyContext()
  const [gameShow, setGameShow] = useState(false)
  const [size, setSize] = useState(null)
  const [allGamesData, setAllGamesData] = useState([])
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('page')) || 2,
  )
  //const [game, setGame] = useState(null);
  const [shownGames, setShownGames] = useState(1)
  const [providers, setProviders] = useState([])
  const [providerData, setProviderData] = useState({})
  const [gameLoader, setGameLoader] = useState(false)
  
  
  const isAllgame = activeCategory === 'all-games' || false

  const handleGameShow = () => {
    setGameShow(true)
  }
  // const handleGameClose = () => {
  //   setGameShow(false);
  //   setIsGamePlaying(false);
  //   navigate(pathname);
  // };
  const headerGamePopup = gameShow && 'gamePopup'

  const getPageNumber = (pageNum) => {
    if (pageNum > 1) {
      setPage(pageNum)
      getGamesByCatergory()
    }
  }

  const [searchvalue, setSearchValue] = useState('')

  const debounce = (func, delay) => {
    let timer
    return (...args) => {
      clearTimeout(timer)
      timer = setTimeout(() => func(...args), delay)
    }
  }

  // Debounced version of handleSearch
  const debouncedHandleSearch = debounce((value) => {
    setSearchValue(value)
  }, 500)

  const handleSearch = (e) => {
    const { value } = e.target
    // if (value === undefined) {
    //   debouncedHandleSearch("");
    // } else {
    debouncedHandleSearch(value)
    // }
  }

  // games list fetch here============
  useEffect(() => {
    // window.scrollTo(0, 0);
    const getGames = async () => {
      let payload = {
        limit: 30,
        pageNumber: 1,
        popular: 0,
        isMobile: windowWidth < 576 ? true : false,
        userId: userDetails?.id || false,
      }
      setPage(2)
      if (isAllgame) {
        payload.allGame = true
        if (providerData?.masterCasinoProviderId) {
          payload.providerId = providerData?.masterCasinoProviderId
          if (searchvalue) payload.search = searchvalue
        } else if (searchvalue) {
          payload.search = searchvalue
        }
      } else if (activeCategory) {
        if (providerData?.masterCasinoProviderId) {
          payload.providerId = providerData?.masterCasinoProviderId
          if (searchvalue) payload.search = searchvalue
        } else if (searchvalue) {
          payload.search = searchvalue
        } else {
          payload.subCategoryId = activeCategory
        }
      }
      try {
        setGameLoader(true)
        if (activeCategory === 'lobby') {
          if (searchvalue) {
            setShowLobbyGames(false)
          } else {
            setShowLobbyGames(true)
          }
        }
        let res = await getAllgamesByCategory(payload)
        let newGames = res?.data?.games || []
        if (res?.msg === 'success') {
          setSize(res?.data?.queriedCount)
          setShownGames(newGames?.length)
          setAllGamesData(newGames)
          if (newGames?.length > 0) {
            const favoriteGames = newGames
              .filter(
                (game) => game?.UserFavoriteCasinoGame?.userMasterCasinoGameId,
              )
              .map(
                (game) => game?.UserFavoriteCasinoGame?.userMasterCasinoGameId,
              )
            setFavorites(favoriteGames)
          }
          setGameLoader(false)
        } else {
          setGameLoader(false)
        }
      } catch (error) {
        setGameLoader(false)
      }
    }
    // if (userDetails?.id) {
    if (sessionStorage.getItem('pageSize') !== null) {
      getGamesByCatergory()
    } else {
      getGames()
    }

    // }
    // eslint-disable-next-line
  }, [
    userDetails,
    providerData?.masterCasinoProviderId,
    searchvalue,
    activeCategory,
  ])

  const getGamesByCatergory = async () => {
    try {
      const pageSizeFromStorage = sessionStorage.getItem('pageSize')
      let payload = {
        limit: pageSizeFromStorage || 30,
        isMobile: windowWidth < 576 ? true : false,
        userId: userDetails?.id ? userDetails?.id : false,
      }
      if (pageSizeFromStorage === null) {
        payload.pageNumber = page
        payload.popular = 0
      } else {
        setGameLoader(true)
      }
      if (isAllgame) {
        payload.allGame = true
      }
      if (providerData?.masterCasinoProviderId) {
        if (isAllgame) {
          payload.providerId = providerData?.masterCasinoProviderId
          payload.allGame = true
        } else if (activeCategory) {
          payload.providerId = providerData?.masterCasinoProviderId
          // payload.subCategoryId = id;
        }
        setPage(page + 1)
      }
      if (activeCategory && !providerData?.masterCasinoProviderId) {
        payload.subCategoryId = activeCategory
      }
      setShowMoreLoading(true)
      let res = await getAllgamesByCategory(payload)
      let newGames = res?.data?.games || []
      // if (!searchvalue) return setAllGamesData(newGames);
      if (res?.msg === 'success') {
        sessionStorage.removeItem('pageSize')
        setGameLoader(false)
        setSize(res?.data?.queriedCount)
        setShownGames((prevLength) => prevLength + newGames?.length)
        setAllGamesData((prevData) => [...prevData, ...newGames])
        if (newGames?.length > 0) {
          const favoriteGames = newGames
            .filter(
              (game) => game?.UserFavoriteCasinoGame?.userMasterCasinoGameId,
            )
            .map((game) => game?.UserFavoriteCasinoGame?.userMasterCasinoGameId)

          setFavorites(favoriteGames)
        }
        setShowMoreLoading(false)
      } else {
        toast.error(res?.msg, {
          toastId: 'game-error',
        })
      }
    } catch (error) {
      setShowMoreLoading(false)
      // setGameLoader(false);
    }
  }

  const getAllProvidersData = async () => {
    try {
      const res = await getAllProviders()
      if (res?.msg === 'success') {
        setProviders(res?.data?.providers)
      }
    } catch (err) {
    setProviders([])
    }
  }

  // useEffect(() => {
  //   getGamesByCatergory();
  //   // eslint-disable-next-line
  // }, [page, searchvalue]);

  useEffect(() => {
    getAllProvidersData()
    // eslint-disable-next-line
  }, [])
  // get gameId from onclick===
  const getGameDetails = () => {
    if (!localStorage.getItem('token')) {
      handleLoginShow()
    }
  }

  // get proviser details from onclick===
  const getProviderDetails = (provider) => {
    if (provider) setProviderData(provider)
  }

  const settings = {
    spaceBetween: 5,
    slidesPerView: 'auto',
    touchStartPreventDefault: false,
    freeMode: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    speed: 100,
    mousewheel: true,
    breakpoints: {
      320: {
        slidesPerView: 'auto',
        navigation: false,
      },
      425: {
        slidesPerView: 'auto',
      },
      768: {
        slidesPerView: 'auto',
      },
      1024: {
        slidesPerView: 'auto',
      },
      1200: {
        slidesPerView: 'auto',
      },
    },
  }

  const handleFilterGameWithSubCategory = async (e, categories) => {
    e.preventDefault()
    setShowLobbyGames(false)
    setActiveCategory(categories?.masterGameSubCategoryId || categories)
    sessionStorage.removeItem('scrollPosition')
  }

  const handleRedirectOnAllGame = () => {
    //navigate(`/view-all/lobby/lobby`);
    setActiveCategory('lobby')
    setShowLobbyGames(true)
    sessionStorage.removeItem('scrollPosition')
  }

  useEffect(() => {
    if (!gameLoader && !showLobbyGames) {
      const savedScrollPosition = sessionStorage.getItem('scrollPosition')
      if (savedScrollPosition) {
        window.scrollTo(0, parseInt(savedScrollPosition))
        // sessionStorage.removeItem("scrollPosition");
      }
    }
    // eslint-disable-next-line
  }, [allGamesData?.length > 0, gameLoader])

  const swiperRef = useRef(null)
  useEffect(() => {
    if (swiperRef?.current && gameSubCategory) {
      // const activeCategory = pathname?.split("/")[3];
      let activeIndex = 0
      if (activeCategory?.toString()?.toLowerCase() === 'lobby') {
        activeIndex = 0
      } else if (activeCategory?.toString()?.toLowerCase() === 'favorite') {
        activeIndex = 1
      } else {
        activeIndex = gameSubCategory.findIndex(
          (category) =>
            category.masterGameSubCategoryId ==
            activeCategory,
        )
      }
      // if (activeIndex !== -1) {
      //   activeIndex += 2;
      // }

      if (activeIndex !== -1 && swiperRef.current) {
        swiperRef.current.swiper.slideTo(activeIndex, 500, false)
        swiperRef.current.swiper.update()
      }
    }
  }, [activeCategory, gameSubCategory, swiperRef])
  return (
    <>
      <Layout headerGamePopup={headerGamePopup}>
        <div className="container">
          <main className="casino">
            <div className="all-games-banner">
              <div className="page-title-2">
                <div className="name">
                  <span text_key="CASINO__BRAND">Versebit</span>{' '}
                  <span text_key="CASINO__CASINO">CASINO</span>
                </div>
                {/* <img src={titleLogo} alt="title" /> */}
              </div>

              <div className="game-filter-wrapper">
                {/* {pathname !== "/view-all/false/lobby/lobby" && ( */}
                <GameSearch
                  allGamesData={allGamesData}
                  // onFilteredData={handleFilteredData}
                  page={page}
                  searchvalue={searchvalue}
                  setSearchValue={setSearchValue}
                  setShowMoreLoading={setShowMoreLoading}
                  allGamesByCategory={getGamesByCatergory}
                  providers={providers}
                  getProviderDetails={getProviderDetails}
                  handlechange={handleSearch}
                />
                {/* )} */}
                {searchvalue === '' ? (
                  <>
                    <div className="bottom-filter slider-navigation-mobile">
                      <Swiper
                        ref={swiperRef}
                        {...settings}
                        modules={[Keyboard, Scrollbar, Navigation, FreeMode]}
                      >
                        <SwiperSlide>
                          <div
                            className="filter-categories"
                            onClick={handleRedirectOnAllGame}
                            role="presentation"
                          >
                            <div
                              className={`category-item d-inline-block align-content-center w-100 text-center ${
                                activeCategory !== 'lobby' ? '' : 'active'
                              }`}
                            >
                              Lobby
                            </div>
                          </div>
                        </SwiperSlide>
                        {/* {userDetails?.agentId &&
                          userDetails?.agentId !== "" && ( */}
                        {/* <SwiperSlide>
                          <div
                            className="filter-categories"
                            onClick={(e) =>
                              handleFilterGameWithSubCategory(e, {
                                masterGameSubCategoryId: "1",
                                name: "New Games",
                              })
                            }
                            role="presentation"
                          >
                            <div
                              className={`category-item d-inline-block align-content-center w-100 text-center ${
                                name !== "New-Games" ? "" : "active"
                              }`}
                            >
                              New Games
                            </div>
                          </div>
                        </SwiperSlide> */}
                        <SwiperSlide>
                          <div
                            className="filter-categories"
                            onClick={(e) =>
                              handleFilterGameWithSubCategory(e, 'favorite')
                            }
                            role="presentation"
                          >
                            <div
                              className={`category-item d-inline-block align-content-center w-100 text-center ${
                                activeCategory !== 'favorite' ? '' : 'active'
                              }`}
                            >
                              Favorite
                            </div>
                          </div>
                        </SwiperSlide>

                        {/* )} */}
                        {gameSubCategory &&
                          gameSubCategory?.length > 0 &&
                          gameSubCategory
                            ?.sort((a, b) => {
                              // If a category doesn't have a rank, place it at the top
                              if (!a.category_rank) return -1
                              if (!b.category_rank) return 1
                              // Otherwise, sort by rank
                              return a.category_rank - b.category_rank
                            })
                            .map((el, index) => (
                              // eslint-disable-next-line
                              <SwiperSlide>
                                <div
                                  key={index}
                                  title={el?.name}
                                  className={`filter-categories d-flex align-items-center`}
                                  onClick={(e) =>
                                    handleFilterGameWithSubCategory(e, el)
                                  }
                                  role="presentation"
                                  // eslint-disable-next-line
                                >
                                  
                                  <div
                                    className={`category-item align-content-center w-100 text-center ${
                                      Number(el?.masterGameSubCategoryId) ===
                                      Number(
                                        activeCategory !== 'lobby' &&
                                          activeCategory !== 'favorite'
                                          ? activeCategory
                                          : 0,
                                      )
                                        ? 'active'
                                        : ''
                                    }`}
                                  >
                                    {el?.name}
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                      </Swiper>
                    </div>

                    <div className="d-flex align-items-center slider-navigation-desktop">
                      <div className="swiper-button-prev">
                        <img src={leftArrow} alt="Previous" />
                      </div>
                      <div className="bottom-filter">
                        <Swiper
                          ref={swiperRef}
                          {...settings}
                          modules={[Keyboard, Scrollbar, Navigation, FreeMode]}
                        >
                          <SwiperSlide>
                            <div
                              className="filter-categories"
                              onClick={handleRedirectOnAllGame}
                              role="presentation"
                            >
                              <div
                                className={`category-item d-inline-block align-content-center w-100 text-center ${
                                  activeCategory !== 'lobby' ? '' : 'active'
                                }`}
                              >
                                Lobby
                              </div>
                            </div>
                          </SwiperSlide>
                          {/* {userDetails?.agentId &&
                            userDetails?.agentId !== "" && ( */}
                          {/* <SwiperSlide>
                            <div
                              className="filter-categories"
                              onClick={(e) =>
                                handleFilterGameWithSubCategory(e, {
                                  masterGameSubCategoryId: "1",
                                  name: "New Games",
                                })
                              }
                              role="presentation"
                            >
                              <div
                                className={`category-item d-inline-block align-content-center w-100 text-center ${
                                  name !== "New-Games" ? "" : "active"
                                }`}
                              >
                                New Games
                              </div>
                            </div>
                          </SwiperSlide> */}
                          <SwiperSlide>
                            <div
                              className="filter-categories"
                              onClick={(e) =>
                                handleFilterGameWithSubCategory(e, 'favorite')
                              }
                              role="presentation"
                            >
                              <div
                                className={`category-item d-inline-block align-content-center w-100 text-center ${
                                  activeCategory !== 'favorite' ? '' : 'active'
                                }`}
                              >
                                Favorite
                              </div>
                            </div>
                          </SwiperSlide>
                          {/* )} */}
                          {gameSubCategory &&
                            gameSubCategory?.length > 0 &&
                            gameSubCategory?.map((el) => (
                              <SwiperSlide>
                                <div
                                  title={el?.name}
                                  className={`filter-categories d-flex align-items-center`}
                                  onClick={(e) =>
                                    handleFilterGameWithSubCategory(e, el)
                                  }
                                  role="presentation"
                                >
                                  <div
                                    className={`category-item align-content-center w-100 text-center ${
                                      Number(el?.masterGameSubCategoryId) ===
                                      Number(
                                        activeCategory !== 'lobby' &&
                                          activeCategory !== 'favorite'
                                          ? activeCategory
                                          : 0,
                                      )? 'active' 
                                        : activeCategory === el?.masterGameSubCategoryId ? 'active':''
                                    }`}
                                  >
                                    {el?.name}
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                        </Swiper>

                        {/* <Slider {...settings}>
                    <div
                      className="filter-categories"
                      onClick={handleRedirectOnAllGame}
                      role="presentation"
                    >
                      <div
                        className={`category-item d-inline-block align-content-center w-100 text-center ${
                          name !== "lobby" ? "" : "active"
                        }`}
                      >
                        Lobby
                      </div>
                    </div>

                    <div
                      className="filter-categories"
                      onClick={() =>
                        handleFilterGameWithSubCategory("favorite")
                      }
                      role="presentation"
                    >
                      <div
                        className={`category-item d-inline-block align-content-center w-100 text-center ${
                          name !== "favorite" ? "" : "active"
                        }`}
                      >
                        Favorite
                      </div>
                    </div>
                    {gameSubCategory &&
                      gameSubCategory?.length > 0 &&
                      gameSubCategory.map((el) => (
                        // eslint-disable-next-line
                        <a
                          title={el?.name}
                          className={`filter-categories d-flex align-items-center`}
                          onClick={() => handleFilterGameWithSubCategory(el)}
                          role="presentation"
                        >
                          <div
                            className={`category-item text-truncate d-inline-block align-content-center w-100 text-center ${
                              el?.name === name?.replace(/-/g, " ")
                                ? "active"
                                : ""
                            }`}
                          >
                            {el?.name}
                          </div>
                        </a>
                      ))}
                  </Slider> */}
                      </div>
                      <div className="swiper-button-next">
                        <img src={rightArrow} alt="Next" />
                      </div>
                    </div>
                  </>
                ) : !gameLoader ? (
                  <div className="w-100 text-start">
                    <h6 className="mb-0">
                      Searched Results:{' '}
                      <span className="searchCount">
                        {allGamesData?.length}
                      </span>
                    </h6>
                  </div>
                ) : null}

                {/* <div className="dropdown-cont" tabIndex="0">
                  <div className="custom-select provider-filter" id="">
                    <input
                      type="text"
                      name="categories"
                      placeholder="Categories"
                    />
                    <img
                      className="drop-arrow"
                      src={arrowdown}
                      alt="arrow-down"
                    />
                  </div>
                  <div className="options-wrapper">
                    <ul id="custom-select-opt">
                      <li>
                        Shs
                        <span>35</span>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="content mb-3">
              {showLobbyGames ? (
                <Lobby setShowLobbyGames={setShowLobbyGames} />
              ) : (
                <GameCard
                  searchvalue={searchvalue}
                  handleGameShow={handleGameShow}
                  allGamesData={allGamesData}
                  loading={loadingShowMore}
                  getPageNumber={getPageNumber}
                  page={page}
                  queriedCount={size}
                  getGame={getGameDetails}
                  shownGames={shownGames}
                  size={size}
                  gameLoader={gameLoader}
                />
              )}
            </div>
            {/* {userDetails?.email && gameShow && (
              <GamePopup
                handleGameClose={handleGameClose}
                game={game}
                windowWidth={windowWidth}
                isGamePlaying={isGamePlaying}
              />
            )} */}
          </main>
        </div>
      </Layout>
    </>
  )
}

export default Casino

import React, { useState } from "react";
import authbanner from "../../assets/images/header/auth/jackbit-banner.png";
import username from "../../assets/images/header/auth/icon/username43a0.svg";
import password from "../../assets/images/header/auth/icon/password43a0.svg";
import closeIcon from "../../assets/images/header/auth/icon/close.svg";
import "./style.css";
import { Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { loginUser } from "../../utility/Apis";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useMyContext } from "../context";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function LoginPopup({
  handleLoginClose,
  handleLoginShow,
  handleSignupShow,
  // handleForgotShow,
  handleForLogin,
}) {
  const { loading, setLoading, setGameShow } = useMyContext();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  // react query api call =======================
  const mutation = useMutation({
    mutationFn: async (userData) =>
      await loginUser({
        ...userData,
        sessionId: localStorage.getItem("sessionId"),
      }),
    onMutate: () => {
      // Set loading to true when the mutation starts
      setLoading(true);
    },
    onSuccess: (data) => {
      const { tokens, status } = data || {};
      if (status === 200) {
        const { token } = tokens?.access || {};
        const { refresh } = tokens || {};
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refresh?.token);
        handleLoginClose();
        handleForLogin();
        setLoading(false);
        // toast for showing message
        toast.success("Login Successfully", {
          autoClose: 2000,
          position: "top-right",
          toastId: "login-success",
        });
        setLoading(false);
        navigate("/view-all/lobby/lobby");
      } else {
        setLoading(false);
        console.log("Something went wrong");
        toast.error(data?.message, {
          toastId: "login-error",
          autoClose: 2000,
        });
      }
    },
    onError: (error) => {
      toast.error(error.message);
      setLoading(false);
    },
    onSettled: () => {
      // Reset loading state when the mutation is completed (success or failure)
      setLoading(false);
    },
  });

  function isValidEmailOrUsername(input) {
    // Regular expression pattern for email address
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.com)$/;
    // Regular expression pattern for username
    const usernameRegex = /^[a-zA-Z0-9_-]{8,20}$/;
    // Test input against both email and username regex patterns
    return emailRegex.test(input) || usernameRegex.test(input);
  }

  const formSubmit = (data, e) => {
    e.preventDefault();
    setGameShow(false);
    // Example usage:
    if (isValidEmailOrUsername(data?.email)) {
      mutation.mutate(data);
    } else {
      toast.error("Invalid Username or Email address", {
        toastId: "Email_error",
      });
    }
  };

  return (
    <div className="overlay auth-popup">
      <div className="popup box">
        <div className="content h-100">
          <div className="banner">
            <img src={authbanner} alt="auth-banner" />
          </div>
          <form className="auth" onSubmit={handleSubmit(formSubmit)}>
            <div className="d-flex justify-content-between">
              <ul className="menu menus-vers">
                <li
                  className="login active"
                  text_key="LOGIN__LOG_IN"
                  onClick={handleLoginShow}
                >
                  Log In
                </li>
                {/* <li
                  className="signup me-0"
                  text_key="LOGIN__SIGN_UP"
                  onClick={handleSignupShow}
                >
                  Sign Up
                </li> */}
              </ul>
              <span className="close" onClick={handleLoginClose}>
                <img src={closeIcon} alt="closeIcon" />
              </span>
            </div>
            <ul className="fields h-100">
              <li>
                <input
                  type="text"
                  name="email"
                  placeholder="Username"
                  autoComplete="off"
                  {...register("email", {
                    required: "Username is required",
                  })}
                  className={`${
                    errors?.email?.type === "required" && "dirty invalid"
                  }`}
                />

                <img src={username} alt="username" />
              </li>
              {errors.email && errors.email.message && (
                <li className="err-text" style={{ textAlign: "start" }}>
                  {errors.email.message}
                </li>
              )}

              <li>
                <input
                  type={!showPassword ? "password" : "text"}
                  name="Password"
                  placeholder="Password"
                  autoComplete="off"
                  {...register("password", {
                    required: "Password is required",
                    // pattern: {
                    //   value:
                    //     // eslint-disable-next-line
                    //     /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':".,/<>?\|`~])(?=.*\d)(?=.{8,})/,
                    //   message:
                    //     "Password must contain at least one uppercase letter, one special character, one number, and be at least 8 characters long",
                    // },
                    pattern: {
                      value:
                        // eslint-disable-next-line
                        /^.{6,16}$/,
                      message: "The password must have at least 6 characters.",
                    },
                  })}
                  className={`${
                    errors?.password && "dirty invalid"
                  } input-sub-icon`}
                />
                {showPassword ? (
                  <Visibility
                    role="button"
                    className="show-password"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                ) : (
                  <VisibilityOff
                    role="button"
                    className="show-password"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                )}
                <img src={password} alt="password" />
              </li>
              {errors?.password && errors?.password?.message && (
                <li
                  className="err-text"
                  field="Password"
                  style={{ textAlign: "start" }}
                >
                  {errors?.password?.message}
                </li>
              )}
              {/* <li className="forgot-password">
                <span
                  text_key="LOGIN__FORGOT_YOUR_PASSWORD?"
                  onClick={handleForgotShow}
                >
                  Forgot Your Password?
                </span>
              </li> */}
              <li className="mt-4">
                <Button className="btn" text_key="LOGIN__LOG_IN" type="submit">
                  {loading ? (
                    <div style={{ zIndex: 99, textAlign: "center" }}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="md"
                        role="status"
                        aria-hidden="true"
                        variant="light"
                      />
                    </div>
                  ) : (
                    "Log In"
                  )}
                </Button>
              </li>

              {/* {errors.email &&
                errors.email.message &&
                errors?.password &&
                errors?.password.message && (
                  <li className="err-text main-err">
                    {" "}
                    Incorrect email or password
                  </li>
                )} */}

              <li className="account-auth">
                <p>
                  <span text_key="LOGIN__DO_NOT_YOU_HAVE_AN_ACCOUNT?">
                    Don't you have an account?
                  </span>
                  <b
                    className="signup"
                    text_key="LOGIN__SIGN_UP"
                    onClick={handleSignupShow}
                  >
                    Sign Up
                  </b>
                </p>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginPopup;
